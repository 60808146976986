.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
}

.card {
    position: relative;
    display: block;
    /* height: 100%; */
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
}

.card__image {
    width: 100%;
    height: 100%;
    /* height: auto; */
}

.card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(var(--curve) * 1px);
    background-color: var(--surface-color);
    transform: translateY(100%);
    transition: .2s ease-in-out;

    background-color: #124e75;

}

.card:hover .card__overlay {
    transform: translateY(0);
}

.card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
    background-color: #124e75;
}

.card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

.card__arc path {
    fill: var(--surface-color);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card:hover .card__header {
    transform: translateY(0);
}

.card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #6A515E;
}

.card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";
    font-size: .8em;
    color: #D7BDCA;
}

.card__status {
    font-size: .8em;
    color: #D7BDCA;
}

.card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #D7BDCA;
    font-family: "MockFlowFont";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.order-pagination button.btn-primary:hover {
    color: #fff;
    background-color: #f2bc07;
    border-color: #f2bc07;
}

.order-pagination button.btn-primary {
    color: #fff;
    background-color: #124e75;
    border-color: #124e75;
}
.order-pagination button.btn-primary.active {
    color: #fff;
    background-color: #f2bc07;
    border-color: #f2bc07;
}.order-pagination button.btn-primary.active:hover {
    color: #fff;
    background-color: #124e75;
    border-color: #124e75;
}

@media (max-width:600px) {
    .stepspan{
        font-size: 13px;
        width: 75px;
    }
}

.pagination .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #124e75;
    border-color: #124e75;
}
.page-link:hover {
    z-index: 2;
    color: #124e75;
    background-color: #e9ecef;
    border-color: #dee2e6;
}