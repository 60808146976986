/* Googlefont Poppins CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0a2558;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar {
  position: fixed;
  height: 100%;
  width: 240px;
  background: #0A2558;
  transition: all 0.5s ease;
}

.sidebar.active {
  width: 60px;
}

.sidebar .logo-details {
  height: 80px;
  display: flex;
  align-items: center;
}

.sidebar .logo-details i {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  min-width: 60px;
  text-align: center
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.sidebar .nav-links {
  margin-top: 10px;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  height: 50px;
}

.sidebar .nav-links li a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li a.active {
  background: #081D45;
}

.sidebar .nav-links li a:hover {
  background: #081D45;
}

.sidebar .nav-links li i {
  min-width: 60px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

.sidebar .nav-links li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
}

.sidebar .nav-links .log_out {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.home-section {
  position: relative;
  background: #f5f5f5;
  min-height: 100vh;
  width: calc(100% - 240px);
  left: 240px;
  transition: all 0.5s ease;
}

.sidebar.active~.home-section {
  width: calc(100% - 60px);
  left: 60px;
}

.home-section nav {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 240px);
  left: 244px;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.sidebar.active~.home-section nav {
  left: 60px;
  width: calc(100% - 60px);
}

.home-section nav .sidebar-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}

nav .sidebar-button i {
  font-size: 35px;
  margin-right: 10px;
}

.home-section nav .search-box {
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}

nav .search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #F5F6FA;
  border: 2px solid #EFEEF1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}

nav .search-box .bx-search {
  position: absolute;
  height: 40px;
  width: 40px;
  background: #2697FF;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
}

.home-section nav .profile-details {
  display: flex;
  align-items: center;
  background: #F5F6FA;
  border: 2px solid #EFEEF1;
  border-radius: 6px;
  height: 50px;
  min-width: 190px;
  padding: 0 15px 0 2px;
}

nav .profile-details img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}

nav .profile-details .admin_name {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin: 0 10px;
  white-space: nowrap;
}

nav .profile-details i {
  font-size: 25px;
  color: #333;
}

.home-section .home-content {
  position: relative;
  padding-top: 104px;
}

.home-content .overview-boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-bottom: 26px;
}

.overview-boxes .box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 4 - 15px);
  background: #fff;
  padding: 15px 14px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.overview-boxes .box-topic {
  font-size: 20px;
  font-weight: 500;
}

.home-content .box .number {
  display: inline-block;
  font-size: 35px;
  margin-top: -6px;
  font-weight: 500;
}

.home-content .box .indicator {
  display: flex;
  align-items: center;
}

.home-content .box .indicator i {
  height: 20px;
  width: 20px;
  background: #8FDACB;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
}

.box .indicator i.down {
  background: #e87d88;
}

.home-content .box .indicator .text {
  font-size: 12px;
}

.home-content .box .cart {
  display: inline-block;
  font-size: 32px;
  height: 50px;
  width: 50px;
  background: #cce5ff;
  line-height: 50px;
  text-align: center;
  color: #66b0ff;
  border-radius: 12px;
  margin: -15px 0 0 6px;
}

.home-content .box .cart.two {
  color: #2BD47D;
  background: #C0F2D8;
}

.home-content .box .cart.three {
  color: #ffc233;
  background: #ffe8b3;
}

.home-content .box .cart.four {
  color: #e05260;
  background: #f7d4d7;
}

.home-content .total-order {
  font-size: 20px;
  font-weight: 500;
}

.home-content .sales-boxes {
  display: flex;
  justify-content: space-between;
  /* padding: 0 20px; */
}

/* left box */
.home-content .sales-boxes .recent-sales {
  width: 100%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.charge-form {
  width : 25%
}

.home-content .sales-boxes .sales-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sales-boxes .box .title {
  font-size: 24px;
  font-weight: 500;
  /* margin-bottom: 10px; */
}

.sales-boxes .sales-details li.topic {
  font-size: 20px;
  font-weight: 500;
}

.sales-boxes .sales-details li {
  list-style: none;
  margin: 8px 0;
}

.sales-boxes .sales-details li a {
  font-size: 18px;
  color: #333;
  font-size: 400;
  text-decoration: none;
}

.sales-boxes .box .button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.sales-boxes .box .button a {
  color: #fff;
  background: #0A2558;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.sales-boxes .box .button a:hover {
  background: #0d3073;
}

/* Right box */
.home-content .sales-boxes .top-sales {
  width: 35%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px 0 0;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.sales-boxes .top-sales li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.sales-boxes .top-sales li a img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 10px;
  background: #333;
}

.sales-boxes .top-sales li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sales-boxes .top-sales li .product,
.price {
  font-size: 17px;
  font-weight: 400;
  color: #333;
}

/* Responsive Media Query */
@media (max-width: 1240px) {
  .sidebar {
    width: 60px;
  }

  .sidebar.active {
    width: 220px;
  }

  .home-section {
    width: calc(100% - 60px);
    left: 60px;
  }

  .sidebar.active~.home-section {
    /* width: calc(100% - 220px); */
    overflow: hidden;
    left: 220px;
  }

  .home-section nav {
    width: calc(100% - 60px);
    left: 60px;
  }

  .sidebar.active~.home-section nav {
    width: calc(100% - 220px);
    left: 220px;
  }
}

@media (max-width: 1150px) {
  .home-content .sales-boxes {
    flex-direction: column;
  }

  .home-content .sales-boxes .box {
    /* width: 100%; */
    overflow-x: scroll;
    margin-bottom: 30px;
  }

  .home-content .sales-boxes .top-sales {
    margin: 0;
  }
}

@media (max-width: 1000px) {
  .overview-boxes .box {
    width: calc(100% / 2 - 15px);
    margin-bottom: 15px;
  }
}

@media (max-width: 700px) {

  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i {
    display: none;
  }

  .home-section nav .profile-details {
    height: 50px;
    min-width: 40px;
  }

  .home-content .sales-boxes .sales-details {
    width: 560px;
  }
}

@media (max-width: 550px) {
  .overview-boxes .box {
    width: 100%;
    margin-bottom: 15px;
  }

  .sidebar.active~.home-section nav .profile-details {
    display: none;
  }
}

@media (max-width: 400px) {
  .sidebar {
    width: 0;
  }

  .sidebar.active {
    width: 60px;
  }

  .home-section {
    width: 100%;
    left: 0;
  }

  .sidebar.active~.home-section {
    left: 60px;
    width: calc(100% - 60px);
  }

  .home-section nav {
    width: 100%;
    left: 0;
  }

  .sidebar.active~.home-section nav {
    left: 60px;
    width: calc(100% - 60px);
  }
}

.table td img {
  width: 40px;
}

.table td {
  font-weight: 400;
  font-size: 17px;
  color: #000000;
  vertical-align: middle;
  padding: 20px 10px;
}

.react-confirm-alert-body h1 {
  font-size: 35px;
}

.margin-top-20 {
  margin-top: 20px !important;
}

[class*=" fa-"]:not(.fa-stack),
[class*=" glyphicon-"],
[class*=" icon-"],
[class^=fa-]:not(.fa-stack),
[class^=glyphicon-],
[class^=icon-] {
  display: inline-block;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
}

.profile-desc-link i {
  width: 22px;
  font-size: 19px;
  color: #abb6c4;
  margin-right: 5px;
}

.profile-desc-title {
  color: #7f90a4;
  font-size: 17px;
  font-weight: 600;
}

.profile-desc-text {
  color: #7e8c9e;
  font-size: 14px;
}

.portlet {
  margin-top: 0;
  margin-bottom: 25px;
  padding: 0;
  border-radius: 4px;
}

.portlet.bordered {
  border-left: 2px solid #e6e9ec !important;
}

.portlet.light {
  padding: 12px 20px 15px;
  background-color: #fff;
}

.portlet.light.bordered {
  border: 1px solid #e7ecf1 !important;
}

.purchased-product-image {
  height: 120px;
  width: 120px;
}

@media (max-width:1310px) {
  .purchased-product-image {
    height: 100px;
    width: 190px;
}
}
@media (max-width:990px) {
  .purchased-product-image {
    height: 100px;
    width: 190px;
}
}
.status-tags-orders{
  position: relative;
  bottom: 120px;
}
.vc {
  background: #13653f;
  color: #fff;
  width: 95px;
  font-size: 13.5px;
  height: 15px;
  text-align: center;
  /* padding: 6.5px 18px; */
  position: relative;
}
.vc:after {
  width: 0;
  height: 0;
  border: 7.5px solid transparent;
  position: absolute;
  content: "";
  border-top-color: #13653f;
  right: -14px;
  top: 0px;
  border-left-color: #13653f;
}
.vc:before {
    width: 0;
    height: 0;
    border: 8px solid transparent;
    position: absolute;
    content: "";
    border-bottom-color: #13653f;
    right: -14px;
    top: -1px;
}

.vc.in-progress{
  background: #ffc107 !important
}
.vc.in-progress:after,.vc.in-progress:before {
  border-bottom-color: #ffc107;
  border-left-color: #ffc107;
  border-top-color: #ffc107;


}

.purchased-product-image img {
  height: 100%;
  width: 100%;
  border-radius: 25px;
  object-fit: cover;

}

.product-list {
  overflow-y: scroll;
  height: 350px;
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: #dee2e6 !important;
}

.star-ratings {
  color: #0a2558 !important;
}

.btn-primary {
  color: #fff;
  background-color: #0a2558;
  border-color: #0a2558;
}

.btn-primary:hover {
  color: #0a2558;
  background-color: #fff;
  border-color: #0a2558;
}

.btn-primary:focus {
  color: #fff;
  background-color: #0a2558;
  border-color: #0a2558;
}

.accordion-button {
  height: 50px;
}

/* order progress */
.mainWrapper {
  width: 100ch;
  min-width: 40ch;
  max-width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statusBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  position: relative;
  cursor: default;
}

.statusBar::before {
  content: "";
  height: 0.3em;
  background-color: #b7b7b7;
  position: absolute;
  left: var(--padding);
  right: var(--padding);
}

.pBar {
  content: "";
  height: 0.3em;
  border-radius: 0 0 0.15em 0.15em;
  background-color: #0a2558;
  max-width: 100%;
  margin: 10px #000000 solid;
  position: absolute;
  left: 0;
  width: 0%;
}

.statusBar>.node {
  background-color: grey;
  height: 1.2em;
  width: 1.2em;
  border-radius: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.node>.text {
  font-weight: 200;
  color: grey;
  text-align: center;
  position: absolute;
  top: 2.5em;
  cursor: pointer;
}

.node.done {
  background-color: #0a2558;
}

.node>.text.done {
  color: #0a2558;
}

.node>.main {
  position: absolute;
  border: 0.2em grey solid;
  width: 0;
  height: 0;
  border-radius: 100%;
}

.node>.main.done {
  border: 0.2em #0a2558 solid;
  width: 2em;
  height: 2em;
  border-radius: 100%;
}

.mainWrapper>.buttonHolder {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  margin: 1em;
  font-size: 1.3em;
  border-radius: 1.5em;
  cursor: pointer;
}

.button.b-next {
  background-color: #0a2558;
  color: #fff;
}

.button.b-next:hover {
  box-shadow: 3px 5px 6px 0px rgba(0, 0, 0, 0.2);
}

.button.disabled,
.button.disabled:hover {
  opacity: 30%;
  cursor: default;
}

.button.b-next.disabled:hover {
  box-shadow: none;
}

.button.b-back {
  box-shadow: 0px 0px 0px 0.2em #0a2558 inset;
  background-color: #fff;
  color: #0a2558;
}

.btn-outline-primary {
  color: #0a2558;
  border-color: #0a2558;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: #0a2558;
  background-color: #0a2558;
}

.made {
  position: absolute;
  bottom: 0.7em;
}

.made>.credit {
  opacity: 40%;
  color: #4b4b4b;
  text-align: center;
}

/* order progress */

.link-secondary {
  font-size: 15px
}

.user-profile {
  height: 50px;
  width: 50px;
}

.user-profile img {
  height: 100%;
  width: 100%;
}

/* pagination */

.home-section .home-content nav {
  position: absolute;
  bottom: 0px;
  left: unset;
  width: 20%;
  height: 73px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0a2558;
  border-color: #0a2558;
}

.page-link {
  color: #0a2558;
}

.form-select:focus {
  border-color: #0a2558;
}

textarea.form-control {
  min-height: calc(5.5em + .75rem + 2px);
}

.dash-icons {
  color: #0a2558;
}





