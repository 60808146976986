

.how-to-buy-container .top-content {
    background-color: #007bff;
    color: white;
    padding: 40px 0;
}

.how-to-buy-container .main-heading h1 {
    font-size: 36px;
    text-align: center;
    font-weight: bold;
}

.policy-sec {
    padding: 20px 0;
    background-color: #f9f9f9;
}

.policy-section {
    margin-bottom: 20px;
}

.policy-button {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    padding: 12px;
    margin-bottom: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.policy-button:hover {
    background-color: #0056b3;
    color: #fca903;
    cursor: pointer;
}

/* Change color of <strong> text when hovering over the button */
.policy-button strong:hover {
    color: #fca903; /* Changes color to #fca903 */
    cursor: pointer; /* Optional: adds a pointer cursor */
    transition: color 0.3s ease; /* Smooth transition for text color */
}

.policy-content {
    padding-left: 20px;
    font-size: 16px;
    margin-bottom: 15px;
}

.policy-content ul {
    list-style-type: disc;
    margin-left: 20px;
}

.policy-content ul li {
    margin-bottom: 8px;
}

.policy-content ul li:hover {
    color: #fca903; /* Changes text color on hover */
    cursor: pointer; /* Optional */
    transition: color 0.3s ease; /* Smooth transition for text color */
}

h6 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

strong {
    font-weight: 700;
}

.container-fluid {
    padding: 15px;
}

.top-content .main-heading h1 {
    font-size: 36px;
    color: white;
    text-align: center;
}
